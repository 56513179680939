<template>
  <div>
    <router-view ref="rv" />
    <div v-show="isExactActive">
      <div class="navbarCustom" style="margin-bottom: 60px">
        <div class="logo-left" style="margin-left: 5px">
          <img
            class="logo_ic"
            src="@/assets/icons/back_ic.svg"
            @click="$router.go(-1)"
          />
          <span class="nav_title">{{ navTitle }}</span>
        </div>
      </div>
      <b-overlay :show="loading" variant="transparent" no-wrap> </b-overlay>
      <div
        class="songs"
        v-if="loaded"
        style="margin-top: 60px; margin-bottom: 55px"
      >
        <div
          class="song"
          v-for="songs in songs"
          :key="songs.id"
          
        >
          <router-link
            :to="{
              name: 'SongDetail',
              params: {
                id: songs.id,
                slug: song_detail(songs.mp3_title),
                singer: songs.mp3_artist,
                title: songs.mp3_title,
              },
              query: { singer: getSinger(songs.mp3_artist) },
            }"
          >
            <progressive-img
              class="song_img_l"
              :blur="0"
              placeholder="https://alemsesi.com/api/placeholder.webp"
              fallback="https://alemsesi.com/api/placeholder.webp"
              :src="songs.mp3_thumbnail_s"
            />

            <div class="song_data_l">
              <div class="s_title">{{ songs.mp3_title }}</div>
              <div v-if="songs.mp3_artist == 'Beýlekiler (Other)'">
                <div class="s_artist">{{ songs.category_name }}</div>
              </div>
              <div v-else>
                <div class="s_artist">{{ songs.mp3_artist }}</div>
              </div>
            </div>
          </router-link>
        </div>
          <b-button @click="loadMore()" block variant="danger">Ýene ýükle</b-button>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  name: "Songs",
  data() {
    return {
      loaded: true,
      loading: false,
      songs: "",
      title: "",
      artistOther: "",
      pages: 1,
      artistRelated: "",
      category: "",
      isExactActive: true,
      api: "",
      navTitle: "",
    };
  },
  metaInfo() {
    if (this.$route.query.on == "") {
      this.m_title = "Älem Sesinde diňle";
    } else {
      this.m_title = this.$route.query.on + " aýdymlar | Älem Sesi";
    }
    return {
      title: `${this.m_title}`,
      meta: [
        {
          name: "description",
          content:
            this.$route.query.on +
            " aýdymlary Älem Sesinde aňsatlyk bilen diňle we ýükle.",
        },
        {
          property: "og:title",
          content: this.m_title,
        },
        { property: "og:site_name", content: "Älem Sesi" },

        { name: "robots", content: "index,follow" },
      ],
    };
  },
  updated() {
    this.isExactActive = typeof this.$refs.rv === "undefined";
  },

  created() {
    if (this.$route.params.title == "new-songs") {
      this.api = "latest";
      this.navTitle = "Täze ýüklenenler";
    } else if (this.$route.params.title == "trend-songs") {
      this.api = "trend_page";
      this.navTitle = "Trend";
    } else if (this.$route.params.title == "most-downloads") {
      this.api = "top_download";
      this.navTitle = "Iň köp ýüklenen";
    } else if (this.$route.params.title == "popular") {
      this.api = "top_rate";
      this.navTitle = "Populýarny";
    }
  },
  destroyed() {},
  methods: {
    song_detail(title) {
      var slug = "";
      var titleLower = title.toLowerCase();
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      slug = slug.replace(/a|á|à|ã|ả|ạ|ä|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      slug = slug.replace(/o|ó|ò|õ|ỏ|ö|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ü|ử|ự/gi, "u");
      slug = slug.replace(/c|ç/gi, "c");
      slug = slug.replace(/n|ň/gi, "n");
      slug = slug.replace(/s|ş/gi, "s");
      slug = slug.replace(/y|ÿ|ý/gi, "y");
      slug = slug.replace(/đ/gi, "d");
      slug = slug.replace(/\s*$/g, "");
      slug = slug.replace(/ /g, "-");

      return slug;
    },
    getSinger(text) {
      if (
        (text == "Beýlekiler (Other)") |
        (text == "Halk aýdymlary") |
        (text == "Goşgular (Lyrics)") |
        (text == "Daşary ýurt aýdymlary")
      ) {
        
        var catsName = this.category;
        var catName = catsName.replace(/ /g, "");
        return catName;
      } else {
        var noSpacesString = text.replace(/ /g, "");
        return noSpacesString;
      }
    },
    loadMore() {
      this.pages++;
      
      axios
        .get(
          "https://alemsesi.com/api/wep_api.php?" +
            this.api +
            "&page=" +
            this.pages
        )
        .then((response) => {
          this.songs.push(response.data.ONLINE_MP3[0]);
          this.songs.push(response.data.ONLINE_MP3[1]);
          this.songs.push(response.data.ONLINE_MP3[2]);
          this.songs.push(response.data.ONLINE_MP3[3]);
        });
    },
  },

  async beforeMount() {
    
    if (this.$route.params.title == "new-songs") {
      this.api = "latest";
      this.navTitle = "Täze ýüklenenler";
    } else if (this.$route.params.title == "trend-songs") {
      this.api = "trend_page";
      this.navTitle = "Trend";
    } else if (this.$route.params.title == "most-downloads") {
      this.api = "top_download";
      this.navTitle = "Iň köp ýüklenen";
    } else if (this.$route.params.title == "popular") {
      this.api = "top_rate";
      this.navTitle = "Populýarny";
    }
    
    this.loading = true;
    this.loaded = false;

    try {
      
      const response = await fetch(
        "https://alemsesi.com/api/wep_api.php?" +
          this.api +
          "&page=" +
          this.pages
     );
      const data = await response.json();
      this.songs = data.ONLINE_MP3;
      this.loading = false;
      this.loaded = true;
    } catch (error) {
      console.log(error);
      this.loaded = false;
      this.loading = false;
    }
  },

  async mounted() {
    if (this.$route.params.title == "new-songs") {
      this.api = "latest";
      this.navTitle = "Täze ýüklenenler";
    } else if (this.$route.params.title == "trend-songs") {
      this.api = "trend_page";
      this.navTitle = "Trend";
      

    } else if (this.$route.params.title == "most-downloads") {
      this.api = "top_download";
      this.navTitle = "Iň köp ýüklenen";
    } else if (this.$route.params.title == "popular") {
      this.api = "top_rate";
      this.navTitle = "Populýarny";
    }
    
    this.isExactActive = typeof this.$refs.rv === "undefined";
  },
};
</script>

<style></style>
